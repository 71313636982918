<template>
  <div class="py-4">
    <label class="mb-2 text-center text-uppercase font-bold block">Add Sound</label>
    
    <div
      class="py-3 px-3 flex justify-between flex-col rounded outline-gray-400"
    >
      <div class="flex justify-center mb-6">
        <div
          class="border border-solid border-gray-400 py-2 px-4 pointer"
          :class="activeRecord"
          @click="record = true"
        >
          Record
        </div>
        <div
          @click="record = false"
          class="border border-solid border-gray-400 py-2 px-4 pointer"
          :class="activeBrowse"
        >
          Browse
        </div>
        <input ref="file" type="file" class="hidden" />
        
      </div>
      <div class="justify-center" v-if="record">
        <audio-recorder class="md-recording_only"
          @click="activeRecord"
          filename="speaking"
          :attempts="3"
          :time="null"
          :before-recording="callback"
          :after-recording="callback"
          :pause-recording="callback"
          :select-record="callback"
          :before-upload="callback"
          :successful-upload="callback"
          :failed-upload="callback"
          :show-download-button="false"
          :show-upload-button="showUploadButton"
        />
        <div class="md-recording_only">
          <audio v-if="url" id="audio" controls :src="url"></audio>
        </div>
      </div>

      <div class="flex flex-col justify-center" v-else>
        <input
          class="hidden"
          accept="audio/*"
          type="file"
          ref="audioInput"
          @change="setFile"
        />
        <div
          style="align-self: center"
          @click="$refs.audioInput.click()"
          class="py-5 mb-4 px-10 inline-flex align-center rounded bg-victoria text-white pointer"
        >
          <svg
            class="w-8"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            viewBox="0 0 26 26"
            fill="#5B5B5B"
          >
            <path
              d="M12.96875 0.3125C12.425781 0.3125 11.882813 0.867188 10.78125 1.96875L4.21875 8.5625C3.011719 9.773438 3.414063 10 4.6875 10L8 10L8 18C8 19.65625 9.34375 21 11 21L15 21C16.65625 21 18 19.65625 18 18L18 10L21.25 10C22.660156 10 22.957031 9.773438 21.75 8.5625L15.15625 1.96875C14.054688 0.867188 13.511719 0.3125 12.96875 0.3125 Z M 0 19L0 23C0 24.65625 1.34375 26 3 26L23 26C24.65625 26 26 24.65625 26 23L26 19L24 19L24 23C24 23.550781 23.550781 24 23 24L3 24C2.449219 24 2 23.550781 2 23L2 19Z"
              fill="#fff"
            />
          </svg>
          <div class="pl-3 text-left">
            <p class="text-lg text-capitalize">Attach</p>
            <p class="text-lg text-capitalize">Recording</p>
          </div>
        </div>
        <div v-if="src" class="align-center w-full">
          <audio-player :src="src"/>
        </div>
        
      </div>
      <b class="text-danger" v-if="message">{{ message }}</b>
    </div>
      <span id="scrollTop" v-if="message"></span>
  </div>
</template>

<script>
import { putObject} from "@/store/upload";
const AWS = require('aws-sdk');
const spacesBaseUrl = new AWS.Endpoint(process.env.VUE_APP_DIGITALOCEAN_SPACES_ENDPOINT);
const urlMarge = spacesBaseUrl.protocol+'//'+process.env.VUE_APP_DIGITALOCEAN_SPACES_BUCKET+'.'+spacesBaseUrl.host
const baseUrl =urlMarge+'/'+process.env.VUE_APP_DIGITALOCEAN_SPACES_FOLDER+'/'
     
export default {
  props: {
    fileUrl: {
      type: String
    },
    message: {
      type: String,
      default: () => null
    },
  },
  data() {
    return {
      record: true,
      fileName: "",
      dataUrl: '',
      src: '',
      url: this.fileUrl || '',
      showUploadButton: false,
      file_path: "",
      path: 'pte-quiz',
      is_audio_load: true
    };
  },
  watch: {
      fileUrl(value) {
        if(value && this.is_audio_load) {
            this.url = value;
        }
      }
  },
  computed: {
    activeRecord() {
      return this.record ? "bg-victoria text-white" : "";
    },
    activeBrowse() {
      return !this.record ? "bg-victoria text-white" : "";
    }
  },
  methods: {
    callback(data) {
      console.log('data',data)
      const blob = new Blob([data.blob], {type: 'audio/wav'})
      var reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onloadend =(() => {
          var base64String = reader.result;
          this.is_audio_load = false
          this.url = base64String
          var arr = base64String.split(","),
          mime = arr[0].match(/:(.*?);/)[1],
          bstr = atob(arr[1]),
          n = bstr.length,
          u8arr = new Uint8Array(n);
        while (n--) {
          u8arr[n] = bstr.charCodeAt(n);
        }
          const filename = 'audio/'+'speaking_'+data.id+'.wav';
          //this.file_path = path.toLowerCase();
          let newDecodeFile = new File([u8arr], filename, { type: mime });
          // console.log(filename,newDecodeFile)
          //let file_path = renameFile(this.file_path,'speaking_'+data.id+'.wav');
          //renameObject(this.form.file_path, file_path);
          //this.form.file_path = file_path;
          putObject(newDecodeFile, this.path, "public-read");
          const filepath = this.path+'/audio/'+'speaking_'+data.id+'.wav';
          this.$emit('input',baseUrl+filepath);
      })
    },
    
    setFile(e) {
      //call file
      let CustomData = e.target.files[0];
     console.log("CustomData",CustomData)
      this.CustomData = URL.createObjectURL(CustomData)
      this.src = this.CustomData;
      this.file_url = this.src
      //path assign here
      const paths = this.path +'/audio/'+ CustomData.name 
      this.file_path = paths.toLowerCase();
      //filepath name
      //var filePath = CustomData.name 
      //filepath name replace . to 1 and remove wav 
      // var fileName = filePath.split('.').pop();
      // let path1 = filePath.replace(fileName, "1");
      // let path2 = path1.replace('.', "");
      // rename file name 
      // let file_paths = renameFile(this.file_path,path2);
      //  setTimeout(() => renameObject(this.file_path, file_paths),5000)
      // this.file_path = file_paths;
      // console.log("rr",file_paths)
      // put file hare
      this.filepath = this.path+'/audio'
      putObject(CustomData, this.filepath, "public-read")
      this.$emit('input',baseUrl+paths);
    },
}
}
</script>